<!-- https://github.com/wikimedia/toolhub/blob/87c6d82c90251f5d910cb386697d7903ccc5468b/vue/src/components/search/Filters.vue -->
<template>
  <div> 
    <v-text-field
      v-model="query"
      class="searchbar typer"
      label="Start your search here"
      :loading="loading"
      solo
      clearable
      hide-details
      @keydown.enter="onSearch"
      @click:clear="onClear"
    >
    </v-text-field>
    <!-- <v-autocomplete
      :items="results"
      :search-input.sync="search"
      hide-no-data
      hide-selected
      placeholder="Start typing to Search"
      prepend-icon="mdi-database-search"
    ></v-autocomplete> -->
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import axios from 'axios'

export default {
  name: 'SearchBar',
  props: {
    loading: { 
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    query: null,
    keybLanguage: {},
    isLoading: false,
    selected: null,
    supportedLanguages: {
      gu: 'gu-t-i0-und',
      hi: 'hi-t-i0-und',
      sa: 'sa-t-i0-und'
    }
  }),
  computed: {
    ...mapState('languages', ['languages'])
  },
  methods: {
    onSearch() {
      if (!this.query) { return }
      this.$emit( 'search', this.query )
    },
    onClear() {
      this.query = ''
      this.$emit( 'search', this.query )
    },
    setQuery( query ) {
      this.query = query
    },
    clearEntries() {
      this.results = []
    },
    setKeybLanguage(languageObject) {
      if (Object.keys(languageObject).length === 0) {
        this.keybLanguage = Object.assign({}, this.keybLanguage, {})
        localStorage.typerLanguage = JSON.stringify({})

        return
      }
      console.log(`SearchBar.methods setting keyboard language to=${languageObject.shortcode}`)
      this.keybLanguage = Object.assign({}, this.keybLanguage, languageObject)
      localStorage.typerLanguage = JSON.stringify(languageObject)

      if (languageObject.shortcode === 'en') {
        disableTypeWriter('.typer')

        return
      }
      enableTypeWriter('.typer', languageObject.shortcode, 'google')
    },
    fetchEntriesDebounced(val) {
      if (val) { this.clearEntries() } 
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        this.typeWriter(val)
      }, 5000) /* 500ms throttle */
    },
    async typeWriter(lookup) {

      const params = {
        text: lookup,
        itc: this.supportedLanguages['gu'],
        num: 5,
        cp: 0,
        cs: 1,
        ie: 'utf-8',
        oe: 'utf-8',
        app: 'test'
      }

      const serviceUrl = 'https://inputtools.google.com/request?' + new URLSearchParams(params)
      const r = await axios.post(serviceUrl)

      if (r.data) {
        this.results = r.data[1][0][1]
      }
    }
  }
}
</script>