<template>
  <!-- https://github.com/wikimedia/toolhub/blob/87c6d82c90251f5d910cb386697d7903ccc5468b/vue/src/components/search/Filters.vue -->
  <v-container class="ma-0 pa-0">
    <v-row no-gutters>
      <v-col cols="12">
          <div>
            <SearchBar ref="searchbar" :loading="searching" @search="onSearchBarSearch"/>
          </div>
          <div>
            <v-radio-group v-model="options.search" row dense label="Search type">
              <v-radio label="Default" value="default" />
              <v-radio label="Wildcard" value="wildcard" />
              <v-radio label="Regex" value="regex" />
            </v-radio-group>
          </div>
      </v-col>
    </v-row>
    <v-row v-if="response && response.count" class="mt-1" no-gutters>
      <v-col md="3" cols="12">
        <Filters :facets="response.facets" @change="onFilterChange" />
      </v-col>
      <v-col md="9" cols="12" class="pl-1">
        <v-card tile flat dense>
          <v-card-subtitle v-if="response.count" class="pb-1">
            {{ 1 + pageOffset }} - {{ pageOffset + response.results.length }} of
            {{ response.count }} result(s) for ( {{ query }} )
          </v-card-subtitle>
          <v-list three-line>
            <template v-for="(item, index) in response.results">
              <v-subheader v-if="item.header" :key="item.header" v-text="item.header"></v-subheader>
              <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>

              <v-list-item v-else :key="item.id">
                <v-list-item-content>
                  {{ item.documentitemid.documentid.documentid }}
                  <v-list-item-title v-if="item.title"><router-link :to="getSearchUrl(item)">{{ item.title }}</router-link></v-list-item-title>
                  <span v-for="(hi, hiidx) in item.highlight" :key="`${item.id}-hi-${hiidx}`">
                    <span v-html="hi[0]"></span>
                    <span v-if="hiidx+1 < item.highlight.length">, </span>
                  </span>
                  <Breadcrumb :document-item="item.documentitemid" :document="item.documentid" />
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
        <!-- <v-row>
          <v-col cols="12">
            <v-pagination
              v-if="!searching && response.count > 0"
              v-model="page"
              :length="Math.ceil(response.count / pageSize)"
              class="ma-4"
              total-visible="5"
              @input="onPageChange"
            />
          </v-col>
        </v-row> -->
      </v-col>
    </v-row>
    <SearchHelp v-else class="mt-1">
      <template v-if="response && response.count === 0" #extra>
        <v-card-text class="font-weight-medium">No results found.</v-card-text>
      </template>
    </SearchHelp>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import configs from '@/configs'
import types from '@/helpers/types'
import SearchBar from '@/components/search/SearchBar'
import SearchHelp from '@/components/search/SearchHelp'
import Filters from '@/components/search/Filters'
import helperUtils from '@/helpers/utils'
import Breadcrumb from '@/components/documentItems/Breadcrumb'

export default {
  name: 'SearchPage',
  components: {
    SearchBar,
    SearchHelp,
    Filters,
    Breadcrumb
  },
  data: () => ({
    query: null,
    page: 1,
    pageSize: 12,
    searching: false,
    facets: [],
    ordering: '-score',
    showOptions: false,
    options: {
      search: localStorage.getItem('appSearchOptions') || 'default'
    },
    respondToRouteChanges: true
  }),
  computed: {
    ...mapState('search', { response: 'documentSearchResults', queryParams: 'documentSearchQueryParams' }),
    pageOffset() { return this.pageSize * ( this.page - 1 ) }
  },
  watch: {
    options: {
      deep: true,
      handler(a) {
        console.log(JSON.stringify(a))
        localStorage.setItem('appSearchOptions', a.search)
      }
    },
    /**
     * React to changes in stored query parameters.
     *
     * @param {URLSearchParams} newParams
     * @param {URLSearchParams|null} oldParams
     */
    queryParams( newParams, oldParams ) { // eslint-disable-line no-unused-vars
      
      if ( newParams === undefined ) { return }
      if (JSON.stringify(newParams) === JSON.stringify(oldParams)) { return }
      const query = {}

      for ( const key of newParams.keys() ) {
        query[key] = newParams.getAll( key )
      }

      this.searching = false
      this.query = newParams.get('q')
      this.doSearch()
      this.respondToRouteChanges = false
      this.$router.push( { query } ).catch( () => {} )
    }
  },
  mounted() {

    if ( !this.searching && this.$refs.searchbar && this.respondToRouteChanges) {
      // this.doSearch()
      console.warn(`SearchPage.mounted called: loadStateFromQueryString`);

      if ( this.loadStateFromQueryString() ) {
        this.doSearch()
      }
    }
  },
  methods: {
    getSearchUrl(o) {
      // const breadcrumb = JSON.parse(o.docu)
      if (o.documentitemid.breadcrumb) {
        const breadcrumb = JSON.parse(o.documentitemid.breadcrumb)
        const leafNode = breadcrumb.find((bi) => bi.isleafnode)
        const lastItem = breadcrumb.slice(-1).pop()
        return `/app/document/${o.documentitemid.documentid.documentid}/documentitem/${leafNode.id}?documentitem_focus=${lastItem.id}`
      }
      return '#'
    },
    onSearchBarSearch( query ) {
      this.query = query
      this.page = 1
      this.doSearch()
    },
    // onPageChange( page ) {
    //   this.page = page
    //   this.doSearch()
    // },
    onFilterChange( payload ) {
      this.facets = payload
      this.page = 1
      this.doSearch()
    },
    async doSearch() {
      if (!this.query) { 
        return
      }

      const payload = {
        query: this.query,
        page: this.page,
        pageSize: this.pageSize,
        ordering: this.ordering,
        filters: this.facets
      }

      this.searching = true
      await this.$store.dispatch('search/findDocuments', { 
        payload: payload, options: this.options
      })
      this.searching = false
    },
    /**
     * Ensure that something is an array.
     *
     * Reduce clutter and repeated code when handling an input that might be
     * either a scalar or an array with this utility function. Implements this
     * algorithm:
     *
     * - If called with no arguments: return []
     * - If called with one argument that is undefined or null: return []
     * - If called with one argument that is an Array: return argument
     * - Otherwise: return an array containing all arguments
     *
     * @param {...*} [value] - Thing to ensure is wrapped in an array
     * @return {Array} Guaranteed to be an Array
     */
    ensureArray( value ) {
      if ( arguments.length === 0 ) { return [] }
      if ( arguments.length === 1 ) {
        if ( value === undefined || value === null ) { return [] }
        if ( Array.isArray( value ) ) { return value }
      }

      return Array.prototype.slice.call(arguments)
    },
    /**
     * Allow deep linking to search results by reconstructing internal
     * state based on data provided in the current query string.
     *
     * @return {boolean} True if state was updated. False otherwise.
     */
    loadStateFromQueryString() {

      let gotQueryData = false
      const qsFilters = []
      const params = Object.fromEntries(new URLSearchParams(document.location.search.substring(1)).entries())
      console.log(`loadStateFromQueryString params=${JSON.stringify(params)}`)

      if (params.q) {
        this.query = params.q
        this.$refs.searchbar.setQuery( params.q )
        gotQueryData = true
      } else if (params.page) {
        this.page = parseInt( params.page, 10 )
        gotQueryData = true
      } else {
        delete params['q']
        delete params['page']
        
        if ( Object.entries(params).length > 0 ) {
          this.facets = qsFilters
          gotQueryData = true
        }
      }

      return gotQueryData
    }
  }
}
</script>
<style>
highlight {
  color: chocolate;
  font-weight: bolder !important;
}
</style>