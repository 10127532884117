<template>
  <v-card 
    :outlined="outlined" 
    class="d-flex flex-column" 
    max-height="100%" 
    tile 
    flat 
    dense
  >
    <slot name="header"></slot>
    <v-card-text class="px-0 flex-grow-1 overflow-auto">
      <v-list dense shaped>
        <v-list-item-group v-model="value" :multiple="facet.multi" color="primary" @change="updateModel">
          <v-list-item v-for="bucket in facet.buckets" :key="bucket.key" :value="bucket.key">
            <v-list-item-content>{{ bucket.key }}</v-list-item-content>
            <v-list-item-action><v-chip outlined>{{ bucket.doc_count }}</v-chip></v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-card-actions class="flex-grow-0">
      <v-btn v-if="facet.selected.length > 0" block @click="clearSelected">
        <v-icon left>mdi-backspace-outline</v-icon>
        clear
      </v-btn>
    </v-card-actions>
    <slot name="footer"></slot>
  </v-card>
</template>
  
<script>

export default {
  name: 'FilterList',
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    facet: {
      type: Object,
      default: () => {},
      required: true
    },
    outlined: Boolean
  },
  data: () => ( {
    model: undefined,
    value: undefined
  }),
  computed: {},
  watch: {
    facet: {
      deep: true,
      immediate: true,
      handler( facet ) {
        // Set selection state
        this.value = facet.multi ? (facet.selected || null) : (facet.selected || null)
      }
    }
  },
  methods: {
    /**
     * Build search filters for a term facet.
     *
     * @param {Object} facet
     * @param {Array<string>} selected
     * @return {string[][]} List of [key, value] query string parameters
     */
    buildTermFilters( facet, selected ) {
      return selected.map((term) => {
        if ( term === facet.missingValue ) {
          return [facet.missingParam, 1]
        }

        return [facet.param, term]
      })
    },

    /**
     * Build a list of search filters based on selected facet values.
     *
     * @return {string[][]} List of [key, value] query string parameters
     */
    buildFilters() {
      const fname = this.facet.name
      const ftype = this.facet.type
      const selected = this.value
      let filters = []

      switch ( ftype ) {
      case 'terms':
        if (!this.facet.multi) {
          filters = filters.concat(this.buildTermFilters( this.facet, [selected] ))
          break
        }

        filters = filters.concat(this.buildTermFilters( this.facet, selected ))
        break
      default:
  	    throw TypeError(`Unknown type '${ftype}' for facet '${fname}'`)
	  }

      return filters.sort((a, b) => a[0].localeCompare( b[0]))
    },

    /**
	  * Handle a change event from a list item group by emitting a new
    * 'change' event with a payload of query params.
    *
    * @fires change
    */
    updateModel() {
      this.$emit('change', this.buildFilters())
    },

    clearSelected() {
      this.value = this.facet.multi ? [] : null
      this.updateModel()
    }
  }
}
</script>